<template>
  <div class="content-header d-f jc-sb ai-c">
    <div class="header-title">{{ title }}</div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "content-header",
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  height: 60px;
  background: #fff;
  padding: 0 16px;
  .header-title {
    padding: 0 6px;
    border-left: 3px solid #e66700;
    font-weight: 400;
    color: #333333;
  }
}
</style>

