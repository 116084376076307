<template>
  <div class="h-100_">
    <content-header title="通知公告">
      <el-button type="primary" @click="sureSend">确认发送</el-button>
    </content-header>
    <div class="notice-body">
      <div class="notice-title">编辑消息</div>
      <el-tabs v-model="noticeType" type="card">
        <el-tab-pane label="系统消息" name="1">
          <el-input v-model="sendContent" type="textarea" placeholder="请输入文字内容"></el-input>
        </el-tab-pane>
        <el-tab-pane label="操作消息" name="2">
          <div class="d-f ai-c jc-sb">
            <span>是否显示广告</span>
            <el-switch v-model="advertisingFlag"></el-switch>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="notice-title">发送对象</div>
      <el-tabs v-model="sendType" type="card">
        <el-tab-pane label="批量发送" name="1">
          <batch ref="batch" />
        </el-tab-pane>
        <el-tab-pane label="单独发送" name="2">
          <el-input placeholder="请输入接收人手机号码" type="phone" v-model="phone" />
        </el-tab-pane>
      </el-tabs>
      <div class="notice-title">
        文档管理
        <el-button @click="add" style="float: right">文档新增</el-button>
      </div>
      <content-table ref="table" :total="total" :columns="columns" :setting="setting" :page-sizes="[5, 10, 20, 30]"
        :getTableData="getTableDataList" :tableList="tableList" @delete="deleteItem" @edit-outline="edit"
        @view="view"></content-table>
      <el-dialog :close-on-click-modal="false" :title="'文档' + title" :visible.sync="dialogVisible" width="1000px"
        :before-close="handleClose">
        <div style="display: flex; padding: 5px; align-items: center">
          <div style="width: 100px">文档名称：</div>
          <el-input :disabled="title == '查看'" v-model="input1"> </el-input>
        </div>
        <my-editor v-if="dialogVisible" :disabled="title == '查看'" v-model="html"></my-editor>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogCancel">取 消</el-button>
          <el-button type="primary" @click="dialogSure">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("notice");
import ContentTable from "@/components/contentTable.vue";
import Batch from "./batch.vue";
import { $delete, $get, $post, $put } from "../../plugins/axios";
import MyEditor from "../../components/myEditor.vue";
export default {
  name: "school",
  components: {
    ContentHeader,
    Batch,
    ContentTable,
    MyEditor,
  },
  data() {
    return {
      sendContent: "",
      noticeType: "1",
      advertisingFlag: false,
      sendType: "2",
      phone: "",
      total: 0,
      columns: [{ label: "文档名称", prop: "docName" }],
      setting: ["edit-outline", "delete", "view"],
      tableList: [],
      title: "新增",
      dialogVisible: false,
      html: "",
      input1: "",
      id: "",
    };
  },
  methods: {
    ...mapActions(["getAllSchoolList", "addNotice"]),
    getTableDataList(val) {
      $get("/lock/docInfo/page", val).then((res) => {
        this.total = res.total;
        this.tableList = res.list;
      });
    },
    deleteItem(val) {
      this.$confirm(
        "确定要删除【" + val.docName + "】这条数据吗？",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        $delete("/lock/docInfo/" + val.id).then(() => {

          this.$message.info("删除成功");
        }
        ).finally(() => {
          this.getTableDataList();

        });
      });
    },
    edit(val) {
      this.title = "编辑";
      this.id = val.id;

      this.html = val.content;
      this.input1 = val.docName;
      this.dialogVisible = true;
    },
    view(val) {
      this.title = "查看";
      this.html = val.content;
      this.input1 = val.docName;
      this.dialogVisible = true;
    },
    dialogCancel() {
      this.html = "";
      this.input1 = "";
      this.dialogVisible = false;
    },
    add() {
      this.title = "新增";
      this.dialogVisible = true;
    },
    dialogSure() {
      if (!this.input1) {
        this.$message.warning("请输入文档名称");
        return;
      }
      if (this.title == "新增") {
        $post("/lock/docInfo", {
          content: this.html,
          docName: this.input1,
        }).then((res) => {
          this.getTableDataList()
        });
      } else if (this.title == "编辑") {
        $put("/lock/docInfo", {
          content: this.html,
          docName: this.input1,
          id: this.id,
        }).then((res) => {
          this.getTableDataList()
        });
      }
      this.dialogVisible = false;
      this.html = "";
      this.input1 = ""
    },
    getList(item) {
      if (item.children) {
        return item.children.map((it) => this.getList(it)).filter(Boolean);
      } else {
        return item.checked ? item : false;
      }
    },
    sureSend() {
      const { sendContent, noticeType, advertisingFlag, sendType } = this;
      const params = {
        sendContent,
        noticeType,
        advertisingFlag: advertisingFlag ? 0 : 1,
        sendType,
      };
      if (sendType == 1) {
        params.sendTarget = this.$refs.batch.managerList;
        // .map((it) => this.getList(it))
        // .flat(Infinity)
        // .map((it) => it.id)
        // .join(",");
      } else {
        params.sendTarget = this.phone;
      }
      console.log(params);
      this.addNotice(params).then(() => {
        this.$message.success("添加通知成功");

        this.sendContent = "";
        this.noticeType = "1";
        this.advertisingFlag = false;
        this.sendType = "2";
        this.phone = "";
      });
    },
  },
  mounted() {
    this.getAllSchoolList();
    this.$refs.table.getDataInit();
  },
  computed: {
    ...mapState(["schoolList"]),
  },
};
</script>
<style lang="scss" scoped>
.notice-body {
  margin-top: 16px;
  height: calc(100% - 52px);
  overflow: auto;
  background: #fff;
  padding: 16px;
  border: 1px solid #d7d8d9;

  .notice-title {
    padding: 10px 3px;
  }
}
</style>