<template>
  <div class="content-table">
    <el-table @selection-change="handleSelectionChange" :height="height" :data="tableList" style="width: 100%"
      ref="table">
      <el-table-column v-if="needSelection" type="selection" width="55">
      </el-table-column>
      <el-table-column v-else type="index" label="序号">
        <template slot-scope="scope">
          {{
    (queryPages.pageNum - 1) * queryPages.pageSize + 1 + scope.$index
}}
        </template>
      </el-table-column>
      <template v-for="item in columns">
        <el-table-column v-if="item.func" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width"
          show-overflow-tooltip align="left">
          <template slot-scope="scope">
            <span style="margin-right: 10px" v-for="it in scope.row[item.prop]
  .split(':')
  .map((it) => item.func(it, scope.row))
  .filter(Boolean)" :key="it">
              {{ it }}
              <del style="font-size: 12px; color: rgba(0, 0, 0, 0.5)" v-if="item.beforePrice">{{ item.beforePrice(it,
    scope.row)
}}</del>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="left" v-else-if="item.list && item.list.length" :key="item.prop" :prop="item.prop"
          :label="item.label" :width="item.width" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ item.list[scope.row[item.prop]] }}
          </template>
        </el-table-column>
        <el-table-column align="left" v-else-if="item.range && item.range.length == 2" :key="item.prop"
          :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row[item.range[0]] }}

            {{
    scope.row[item.range[1]] ? " 至 " + scope.row[item.range[1]] : ""
}}
          </template>
        </el-table-column>
        <el-table-column align="left" v-else-if="item.date" :key="item.prop" :prop="item.prop" :label="item.label"
          :width="item.width" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getDateFormat(scope.row[item.prop]) }}
          </template>
        </el-table-column>
        <el-table-column align="left" v-else-if="item.imgType" :key="item.prop" :prop="item.prop" :label="item.label"
          :width="item.width">
          <template slot-scope="scope">
            <el-image v-for="it in item.imgList(scope.row[item.prop])" :key="it" style="width: 50px; height: 50px"
              :src="it" :preview-src-list="[it]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="left" v-else-if="item.view" :key="item.prop" :prop="item.prop" :label="item.label"
          :width="item.width">
          <template slot-scope="scope">
            <el-popover  title="" placement="top" width="500" trigger="hover">
              <div v-html="scope.row[item.prop]"></div>
              <div slot="reference">内容</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="left" v-else :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width"
          show-overflow-tooltip>
        </el-table-column>
      </template>

      <el-table-column fixed="right" v-if="setting && setting.length" prop="" label="操作" width="100px" align="left">
        <template slot-scope="scope">
          <div class="d-f ai-c">
            <img title="拉黑" class="'c-p" style="margin: 0 5px; cursor: pointer" v-if="setting.includes('lablack')"
              src="@/assets/lablack.svg" @click="$emit('lablack', scope.row)" alt="" />
            <img title="编辑" class="'c-p" style="margin: 0 5px; cursor: pointer" v-if="setting.includes('edit-outline')"
              src="@/assets/edit.svg" @click="$emit('edit-outline', scope.row)" alt="" />
            <img title="删除" class="c-p" style="margin: 0 5px; cursor: pointer" v-if="setting.includes('delete')"
              src="@/assets/delete.svg" @click="$emit('delete', scope.row)" alt="" />

            <i title="查看数据" v-if="setting.includes('view')" style="margin: 0 5px" @click="$emit('view', scope.row)"
              :class="'c-p el-icon-view'" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="
        height: 64px;
        display: flex;
        align-items: center;
        text-align: right;
        padding-right: 30px;
        justify-content: flex-end;
        background: #fbfbfb;
      ">
      <el-pagination v-bind="$attrs" v-on="$listeners" background :page-sizes="pageSizes || [50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper" @current-change="currentChange"
        :current-page="queryPages.pageNum" :page-size="queryPages.pageSize" @size-change="handleSizeChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
class storeChecked {
  constructor(key) {
    this.key = key;
    this.item = {};
  }
  setPage(list, page) {
    this.item[page] = list;
  }
  getpage(page) {
    return this.item[page] || [];
  }
  getAll() {
    return Object.values(this.item).flat(Infinity);
  }
}
export default {
  name: "content-table",
  data() {
    return {
      height: 250,
      params: {},
      s: null,
      queryPages: {
        pageNum: 1,
        pageSize: this.pageSizes?.[0] || 50,
      },
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    setting: {
      type: Array,
      default: () => [],
    },
    tableList: {
      type: Array,
      default: () => [],
    },
    total: Number,
    getTableData: Function,
    needSelection: Boolean,
    data: Array,
    pageSizes: Array,
  },
  methods: {
    getDateFormat(it) {
      const date = new Date(it);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    },
    handleSizeChange(val) {
      this.queryPages.pageNum = 1;
      this.queryPages.pageSize = val;
      this.getTableData({
        ...this.queryPages,
        ...this.params,
      });
    },
    currentChange(val) {
      this.queryPages.pageNum = val;
      this.getTableData &&
        this.getTableData({
          ...this.queryPages,
          pageNum: val,
          ...this.params,
        });
    },
    getDataInit(params = {}) {
      this.queryPages.pageNum = 1;
      this.params = params;
      this.getTableData &&
        this.getTableData({
          ...this.queryPages,
          pageNum: 1,
          ...params,
        });
    },
    toggleSelection(rows) {
      setTimeout(() => {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.table.toggleRowSelection(row);
          });
        } else {
          this.$refs.table.clearSelection();
        }
      }, 10);
    },
    handleSelectionChange(val) {
      // 多选的item
      this.s.setPage(val, this.queryPages.pageNum);
      this.$emit("selectionChange", this.s.getAll());
    },
  },
  watch: {
    tableList(val) {
      const getPages = this.s
        .getpage(this.queryPages.pageNum)
        .map((it) => it.id);
      this.$nextTick(() => {
        this.toggleSelection(val.filter((it) => getPages.includes(it.id)));
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      const height = this.$el.clientHeight;
      this.height = height - 35; //  设置表格高度
    });

    this.s = new storeChecked();
  },
};
</script>

<style lang="scss" scoped>
.content-table {
  height: calc(100% - 76px);
  margin-top: 16px;
}
</style>

