<template>
  <div>
    <div class="room-body-content w-100_">
      <div class="header">
        <span style="margin-left: 30px">学校</span>
        <span style="margin-left: 90px">楼栋</span>
        <span style="margin-left: 160px">楼层</span>
        <span style="margin-left: 200px">房号</span>
      </div>
      <div class="scroll">
        <my-collapse
          :left="30"
          v-for="university in managerList"
          :key="university.id"
          :label="university.label"
          @change="(val) => houseChange(val, university)"
          @callOpen="getSchoolList(university)"
          :indeterminate="
            getChecked(university) ? false : getIndeterminate(university)
          "
          :checked="getChecked(university)"
        >
          <my-collapse
            :left="120"
            v-for="item in university.children"
            :key="item.id"
            :label="item.label"
            @change="
              (val) => {
                houseChange(val, item);
                item.allChecked = val;
              }
            "
            :indeterminate="getChecked(item) ? false : getIndeterminate(item)"
            :value="getChecked(item)"
          >
            <my-collapse
              :left="160"
              v-for="ytem in item.children || []"
              :value="getChecked(ytem)"
              :indeterminate="
                (ytem.children || []).every((it) => it.checked)
                  ? false
                  : (ytem.children || []).some((it) => it.checked)
              "
              :key="ytem.id"
              :label="ytem.label"
              @change="
                (val) => {
                  houseChange(val, ytem);
                  ytem.allChecked = val;
                }
              "
            >
              <div class="check-box-content d-f ai-c">
                <el-checkbox
                  style="margin: 10px 10px"
                  v-model="ztem.checked"
                  :label="ztem.label"
                  v-for="ztem in ytem.children || []"
                  :key="ztem.id"
                  @change="cccchange"
                />
              </div>
            </my-collapse>
          </my-collapse>
        </my-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import MyCollapse from "./myCollapse.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("notice");
export default {
  name: "batch",
  components: {
    MyCollapse,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getSchoolDetails"]),
    cccchange(val) {
      console.log(val);
    },
    cellChange(val, item) {
      console.log(val, item);
      item.children.forEach((item) => {
        item.checked = val;
      });
    },
    getSchoolList(item) {
      this.getSchoolDetails(item.id).then(() => {
        this.$forceUpdate();
      });
    },
    houseChange(val, item) {
      console.log(val, item);
      if (item && item.children && item.children.length) {
        item.children.forEach((g) => {
          this.houseChange(val, g);
        });
      } else {
        item && (item.checked = val);
      }
    },
    getCheckedFunc(item) {
      if (!item.children) {
        return false;
      }
      if (
        item.children &&
        ((item.children.length > 0 && !item.children[0].children) ||
          item.children.length == 0)
      ) {
        return item.children.every((it) => it.checked);
      } else {
        return item.children.every((it) => this.getCheckedFunc(it));
      }
    },
    getIndeterminateFunc(item) {
      if (!item.children) {
        return false;
      }
      if (
        item.children &&
        ((item.children.length > 0 && !item.children[0].children) ||
          item.children.length == 0)
      ) {
        return item.children.some((it) => it.checked);
      } else {
        return item.children.some((it) => this.getIndeterminateFunc(it));
      }
    },
  },
  computed: {
    getChecked() {
      return (row) => {
        return this.getCheckedFunc(row);
      };
    },
    getIndeterminate() {
      return (row) => {
        return this.getIndeterminateFunc(row);
      };
    },
    ...mapState(["managerList"]),
  },
};
</script>

<style lang="scss" scoped>
.room-body-content {
  background: #fff;
  flex: 1;
  margin-top: 24px;
  .set {
    i {
      cursor: pointer;
      font-size: 16px;
    }
  }
  .check-box-content {
    min-height: 50px;
    padding-left: 200px;
    flex-wrap: wrap;
  }
}
</style> 